<template>
  <div v-if="errors && errors.length > 0"
       class="input-feedback">
    * {{ errors[0] }}
  </div>
</template>

<script>
export default {
  name: 'InputFeedback',
  props: {
    errors: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  }
};
</script>

<style scoped>

</style>
