























import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { IStateProvinceView } from '@/interfaces/_auto/api';
import { StateProvinceApi } from '@/api/StateProvinceApi';
import _ from 'lodash';

@Component
export default class StateProvinceTypeahead extends Vue {

  @Prop({ type: Object, required: false, default: () => null })
  value!: IStateProvinceView;

  @Prop({ type: Boolean, required: false, default: () => false })
  disabled!: boolean;

  @Prop({ type: Number, required: true })
  countryId!: number;

  @Prop({ type: Array, required: false, default: () => [] })
  errors!: string[];

  isLoading = false;
  justChanged = false;
  searchTerm = '';

  stateList: IStateProvinceView[] = [];

  get optionsList() {

    if (this.value) {
      const predicate = (s1, s2) => s1.stateProvinceId === s2.stateProvinceId;

      if (Array.isArray(this.value)) {
        return [...this.value, ...this.stateList];
      } else {
        const item = this.value as IStateProvinceView;
        if (this.stateList.findIndex(s => predicate(s, item)) === -1) {
          return [this.value, ...this.stateList];
        }
      }
    }

    return this.stateList;
  }

  // debounced search function
  doThrottledSearch = _.debounce(this.searchApi, 200);

  // set the loader and kick off the debounced function
  async beginSearch() {
    this.isLoading = true;
    this.doThrottledSearch();
  }


  async searchApi() {
    const stateProvinceSearch = {
      searchPage: {
        pageIndex: 0,
        pageSize: 10,
      },
      countryId: this.countryId,
      stateProvinceName: this.searchTerm,
    };

    try {
      this.isLoading = true;
      const { data } = await StateProvinceApi.searchStateProvinces(stateProvinceSearch);
      this.stateList = data.records;
    } catch (e) {
      this.handleError(e);
      console.error(e);
    } finally {
      this.isLoading = false;
    }

  }

  onInput(val) {
    this.emitInput(val);
  }

  // if the input just changed, the searchTerm will change also - don't search for a moment
  onChange(val) {
    this.justChanged = true;

    setTimeout(() => {
      this.justChanged = false;
    }, 10);

    this.emitChange(val);
  }

  // filtering happens on the back end - return true
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filter(item: IStateProvinceView, queryText: string, itemText: string) {
    return true;
  }

  // control is passing item-value aka numbers to this function
  valueComparator(a: never, b: never): boolean {
    if (!a || !b) {
      return false;
    }

    let isMatch = false;
    if (a === b) {
      isMatch = true;
    }
    // this.$log.debug('Comparing services. Match:', a, b, isMatch);
    return isMatch;
  }

  @Emit('input')
  emitInput(val) {
    return val;
  }

  @Emit('change')
  emitChange(val) {
    return val;
  }

  @Watch('searchTerm')
  async watchSearchTerm(newTerm) {
    if (newTerm && !this.justChanged) {
      await this.beginSearch();
    }
  }
}
