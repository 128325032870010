





















































































































































































































































































































































































































































































import { ResignationApi } from '@/api/ResignationApi';
import moment from 'moment';
import handleError from '@/errorHandler';
import { mapState } from 'vuex';
import InputFeedback from '@/components/InputFeedback.vue';
import { Multiselect } from 'vue-multiselect';
import Vue from 'vue';
import { IResignationView, IResignationUpdateRequest, IResignationNameUpdateRequest } from '@/interfaces/_auto/api';
import { Component, Prop, Watch } from 'vue-property-decorator';
import CountryTypeahead from '@/components/CountryTypeahead.vue';
import StateProvinceTypeahead from '@/components/StateProvinceTypeahead.vue';


export enum personResigningType {
  self = 1,
  child = 2,
  partner = 3
}

@Component({
  components: {
    StateProvinceTypeahead,
    CountryTypeahead,
    InputFeedback,
    Multiselect,
  },
  computed: {
    ...mapState('enums', ['resignationEnums']),
  },
})
export default class ResignationEditComponent extends Vue {

  @Prop({ type: Object, required: true })
  resignation!: IResignationView;

  iHaveFullLegalRights = false;
  submitted = false;

  birthMonthOptions = [];

  personResigning: personResigningType | null = 1;

  resigningSelfOptions = [
    { value: personResigningType.self, text: 'I am resigning myself' },
    { value: personResigningType.child, text: 'I am resigning my child' },
    {
      value: personResigningType.partner,
      text: 'I am adding a resignation on behalf of someone else (spouse, partner, etc)',
    },
  ];

  minorFlagOptions = [
    { value: false, text: 'No. This person is NOT a minor' },
    { value: true, text: 'Yes. This person IS a minor' },
  ];

  parentTwoNotRequiredOptions = [
    { value: false, text: 'The other parent will sign the resignation' },
    {
      value: true,
      text: 'The other parent\'s signature is not required',
    },
  ];

  parentListForUser: string[] = [];

  mounted() {
    this.$store.cache.dispatch('enums/getResignationEnums');
    this.formatBirthDate();
    if (this.resignation) {
      this.pushEmptyNameIfNone(this.resignation);
    }
  }

  async getParentListForUser() {
    const { data } = await ResignationApi.getParentListForUser();
    this.parentListForUser = data;
  }

  async addResignationName() {

    const { value } = await this.$swal({
      title: 'Are you adding an alternate name for the SAME person?',
      text: 'This is for adding an alternate name for the SAME person ONLY. If you want to resign a different person, please complete a new form for them.',
      showCancelButton: true,
      confirmButtonText: 'Add an alternate name',
      cancelButtonText: 'Cancel',
    });

    if (value) {
      // @ts-ignore
      this.resignation.resignationNames.push({
        firstName: null,
        middleName: null,
        surname: null,
        isPrimary: false,
      });
    }
  }

  removeResignationName(resignationName) {
    const idx = this.resignation.resignationNames?.indexOf(resignationName) ?? -1;
    if (idx > -1) {
      this.resignation.resignationNames?.splice(idx, 1);
    }
  }

  get birthDropdownOptions() {
    const options: any[] = [];
    const months = moment.months();
    for (let i = 0; i < months.length; i++) {
      options.push({
        value: i,
        text: months[i],
      });
    }
    return options;
  }

  formatBirthDate() {

    // @ts-ignore
    const year = Number.parseInt(this.resignation.birthYear);
    // @ts-ignore
    const month = Number.parseInt(this.resignation.birthMonth);
    // @ts-ignore
    const day = Number.parseInt(this.resignation.birthDay);

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      this.resignation.birthDate = null;
      return;
    }

    this.resignation.birthDate = new Date(year, month, day);
  }

  pushEmptyNameIfNone(val: IResignationView) {

    if (!val.resignationNames || val.resignationNames.length === 0) {

      const emptyName: IResignationNameUpdateRequest = {
        firstName: undefined,
        middleName: undefined,
        familyName: undefined,
        isPrimary: true,
      };

      val.resignationNames?.push(emptyName);
    }
  }

  get showMinorSection() {
    return this.resignation.minorFlag;
  }

  get showNameSection() {
    return (this.personResigning);
  }


  get nameSectionTitle() {

    return 'Resignation Name';
    // switch (this.personResigning) {
    //   case personResigningType.self:
    //     return 'Your name';
    //
    //   case personResigningType.child:
    //     return 'Child\'s name';
    //
    //   case personResigningType.partner:
    //     return 'Name of the person resigning';
    //
    // }
  }

  get validator() {
    return this.$refs.validationObserver as Vue & {
      validate: () => Promise<boolean>;
      reset: () => boolean;
    };
  }


  async saveResignation() {

    const isValid = await this.validator.validate();

    if (!isValid) {
      return;
    }

    const r = this.resignation;
    const updateRequest: IResignationUpdateRequest = {
      resignationNames: r.resignationNames,
      parentOneName: r.parentOneName,
      parentTwoName: r.parentTwoName,
      birthYear: r.birthYear,
      birthMonth: r.birthMonth,
      birthDay: r.birthDay,
      minorFlag: r.minorFlag,
      parentTwoNotRequiredFlag: r.parentTwoNotRequiredFlag,
      address: {
        streetAddress1: r.address?.streetAddress1 ?? '',
        streetAddress2: r.address?.streetAddress2 ?? '',
        city: r.address?.city ?? '',
        countryId: r.address?.country?.countryId,
        stateProvinceId: r.address?.stateProvince?.stateProvinceId,
        postalCode: r.address?.postalCode ?? '',
        provinceName: r.address?.provinceName ?? '',
      },
    };

    // if not a minor, parentTwoNotRequiredFlag should be false
    if (!this.resignation.minorFlag) {
      updateRequest.parentTwoNotRequiredFlag = false;
    }

    // increment birth month for api
    updateRequest.birthMonth++;

    // updateRequest.address.countryId = this._.get(this.resignation, 'address.country.countryId', null);
    // updateRequest.address.stateProvinceId = this._.get(this.resignation, 'address.stateProvince.stateProvinceId', null);

    try {
      if (!this.resignation.resignationId) {
        const { data } = await ResignationApi.AddResignation(updateRequest);

        // this.$toast.success('Resignation saved');
        this.$emit('resignation-saved', data);
      } else {
        await ResignationApi.UpdateResignation(this.resignation.resignationId, updateRequest);
        // this.$toast.success('Resignation updated');
        this.$emit('resignation-saved', this.resignation.resignationId);
      }

    } catch (e) {
      console.error(e);
      handleError(e);
    }

  }


  notEmptyErrors = function(errorsObject) {

    const errors: string[] = [];
    const keys = Object.keys(errorsObject);

    for (let i = 0; i < keys.length; i++) {
      // get the first item in the array for the key property

      if (errorsObject[keys[i]].length > 0) {
        const errorMessage = errorsObject[keys[i]][0];
        if (errorMessage.length > 0) {
          errors.push(errorMessage);
        }
      }
    }

    return errors;
  };

  get countryHasStates() {
    const countryCode2 = this.resignation.address?.country?.countryCode2 ?? '';
    // US and Canada have states
    return countryCode2 === 'US' || countryCode2 === 'CA';
  }

  get canEditAddress() {
    return !!this.resignation.address?.country;
  }

  get countryId() {
    return this.resignation.address?.country?.countryId;
  }

  get stateProvinceId() {
    return this._.get(this.resignation, 'address.stateProvince.stateProvinceId');
  }


  @Watch('countryId')
  watchCountryId() {
    if (this.resignation.address) {
      const countryCode2 = this.resignation.address?.country?.countryCode2 ?? '';

      if (countryCode2 === 'US' || countryCode2 === 'CA') {
        this.resignation.address.stateProvince = undefined;
      } else {
        this.resignation.address.provinceName = undefined;
      }
    }
  }
}

