import { authAxios } from '@/api';
import { ROOT_URL, API_VER } from '@/app/constants';

const API_CONTROLLER = 'state-province';

export class StateProvinceApi {
  static searchStateProvinces(stateProvinceSearchRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/search`;
    return authAxios.post(url, stateProvinceSearchRequest);
  }
}
