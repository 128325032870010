<template>
  <v-container class="body-container">

    <div v-if="resignation">

      <h1>{{ formTitle }}</h1>

      <role-restricted roles="administrators">
        <b-link @click="loadSampleRecord">
          Load sample
        </b-link>
      </role-restricted>

      <form-wizard ref="formWizard"
                   color="#2d4956"
                   subtitle=""
                   title=""
                   @on-complete="userApproveResignation">

        <tab-content title="Personal Details">
          <h1>Your information</h1>
          <resignation-edit-component :resignation="resignation"
                                      @resignation-saved="onResignationSaved" />
        </tab-content>

        <tab-content title="Download Forms">
          <h1>Download your resignation documents</h1>

          <h2>Instructions</h2>
          <ul>
            <li>Click the button below to generate and download your resignation documents</li>
            <li>Take them to a local notary.</li>
            <li>Sign the documents in front of the notary</li>
            <li>Upload the documents and we will process them</li>
          </ul>

          <!--User has not downloaded since hash changed-->
          <div v-if="!resignation.documentsDownloadedDate">

            <b-button variant="primary"
                      @click="downloadDocuments">
              <font-awesome-icon icon="file-pdf" />
              Download resignation documents
            </b-button>
          </div>

          <!--User has already downloaded-->
          <div v-else>
            <p>
              You downloaded your documents on <b>{{ resignation.documentsDownloadedDate | moment('lll') }}</b>
              ({{ resignation.documentsDownloadedDate | moment('from', 'now') }})
            </p>

            <b-link @click="downloadDocuments">
              <font-awesome-icon icon="file-pdf" />
              Download resignation documents again
            </b-link>

            <div class="mt-3">
              <b-button variant="primary"
                        @click="onResignationDocsDownloadedNext">
                Next
              </b-button>
            </div>
          </div>
        </tab-content>

        <tab-content title="Notarize and Upload">
          <h2>Instructions</h2>

          <ul>
            <li>Bring your resignation documents to a local notary.</li>
            <li>Sign the documents in front of the notary</li>
            <li>Upload the documents and we will process them</li>
          </ul>

          <div v-if="resignation.minorFlag">
            <p>
              <font-awesome-icon icon="info-circle" />
              Both parents must sign for minor children. If you are divorced and have full custody, your custody
              agreement
              must have specific language that you have the ability to make religious decisions on behalf of your child.
              <b-link :to="{ name: 'minor-children' }">
                FAQ about minor Children
              </b-link>
            </p>

            <p v-if="resignation.parentTwoNotRequiredFlag"
               class="bg-warning p-2">
              <font-awesome-icon icon="exclamation-triangle" />
              Please upload your custody agreement with your notarized resignation.
            </p>

          </div>

          <b-card header="Select Files">

            <div class="mb-3">
              <font-awesome-icon icon="exclamation-circle" />
              Maximum size of all documents must be less than {{ maxEmailAttachmentMb }}MB total.
            </div>

            <v-file-input v-model="files"
                          accept="image/jpeg, application/pdf"
                          counter
                          hint="Please upload only PDF or JPG files"
                          label="Select Files"
                          multiple
                          outlined
                          persistent-hint
                          prepend-icon=""
                          show-size />
            <!--:rules="fileUploadRules"-->

            <!--<b-form-file v-model="files"
                         drop-placeholder="Drop files here..."
                         :file-name-formatter="formatFileNames"
                         multiple
                         placholder="Choose files..."
                         :state="true" />-->

            <div v-if="files.length > 0"
                 class="mt-3">
              Selected file(s):

              <table class="table">
                <tr>
                  <th>Name</th>
                  <th>Size</th>
                  <th />
                </tr>
                <tr v-for="f in files"
                    :key="f.resignationFileId"
                    :class="{ 'large-file-error' : isFileSizeError(f.size)}">
                  <td>
                    {{ f.name }}
                  </td>
                  <td>
                    {{ f.size | formatSize }}
                  </td>
                  <td>
                    <b-link @click="removeFile(f)">
                      <font-awesome-icon icon="trash-alt" />
                    </b-link>
                  </td>
                </tr>
              </table>
            </div>

            <div class="mb-3">
              <div class="mt-2">
                Size of upload:
                <span class="text-bold"
                      :class="{'text-danger': uploadTooLarge}">{{ sizeToUpload | formatSize }}</span>
              </div>

              <div v-if="uploadTooLarge"
                   class="bg-warning p-3 m-3">
                <p>
                  <font-awesome-icon icon="exclamation-triangle" />
                  Total size would be <span class="text-bold">{{ totalFileSize | formatSize }}</span>, which exceeds
                  the maximum size of {{ maxEmailAttachmentMb * 1024 * 1024 | formatSize }}. Please resize your
                  documents before uploading.
                </p>

                <p>
                  We are restricted by the attachment size limit of the church's email servers, so we are forced to
                  limit the upload size.
                </p>

              </div>
            </div>

            <b-progress v-if="uploadInProgress"
                        animated
                        class="mt-3 mb-3"
                        :max="100"
                        show-progress
                        :value="uploadProgressPercent" />

            <div slot="footer">
              <b-button :disabled="uploadTooLarge"
                        @click="performUpload">Upload Files
              </b-button>
            </div>
          </b-card>

          <div class="mt-3">
            <user-file-list :allow-delete="true"
                            :documents="documents"
                            @document-deleted="onDocumentDeleted" />
          </div>

          <div class="mt-3">
            <b-button :disabled="!hasAnyDocuments"
                      :variant="hasAnyDocuments ? 'primary': 'secondary'"
                      @click="onResignationDocsUploadedNext">Next Step
            </b-button>
          </div>
        </tab-content>

        <tab-content title="Terms & Conditions">
          <h1>Terms and Conditions</h1>
          <b-form>
            <div class="mb-3">
              <p>By submitting this information I agree that I am the person (or parent or guardian of the person)
                requesting to have my name removed from the records of the Church
                of
                Jesus Christ of Latter-day Saints. I understand that I CANNOT submit the information of another or make
                this request on behalf of another without their express
                permission.</p>
              <p>I acknowledge that once this letter is submitted it cannot be withdrawn. I further acknowledge that I
                will have to go through my bishop or other local authority to
                have
                my name reinstated with the church.</p>

              <v-switch v-model="agreeToTerms"
                        hide-details
                        label="I acknowledge the terms above">
              </v-switch>

              <v-switch v-model="agreeToDataStorage"
                        hide-details
                        label="I acknowledge and consent to the storage and processing of my personal data. We do not share your data with any third parties.">
              </v-switch>
            </div>

            <b-button :disabled="!hasAgreedToTerms"
                      variant="primary"
                      @click="userApproveResignation">
              <font-awesome-icon icon="power-off" />
              Submit my resignation
            </b-button>

          </b-form>
        </tab-content>

        <template slot="footer"
                  slot-scope="props">
          <div class="wizard-footer-left" />
          <div class="wizard-footer-right" />
        </template>
      </form-wizard>
    </div>

    <!--    <pre>
    {{resignation}}
        </pre>-->
  </v-container>
</template>

<script>
import { ResignationApi } from '@/api/ResignationApi';
import handleError from '@/errorHandler';
import { UserDocumentApi } from '@/api/UserDocumentsApi';
import UserFileList from '@/components/UserFileList';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ResignationEditComponent from '@/components/ResignationEditComponent';
import resignationMixins from '@/mixins/resignationMixins.ts';
import RoleRestricted from '@/components/RoleRestricted';
import userMixins from '@/mixins/userStoreMixin.ts';
import frontEndSettingsStoreMixin from '@/mixins/frontEndSettingsStoreMixin';

export default {
  name: 'ResignationDetail',
  components: { RoleRestricted, ResignationEditComponent, UserFileList, FormWizard, TabContent },
  mixins: [resignationMixins, userMixins, frontEndSettingsStoreMixin],
  data: function() {
    return {

      formTitle: 'Create Resignation',
      // data coming from mixin
      // resignation: {},

      downloadInProgress: false,
      uploadInProgress: false,

      agreeToTerms: false,
      agreeToDataStorage: false,

      documents: [],
      files: [],
      uploadProgressPercent: 0,
    };
  },
  async mounted() {
    this.parseRouteParams();

    if (this.resignationId) {
      // TODO: move to file list component
      await this.getFilesForResignation(this.resignationId);

      await this.getResignation(this.resignationId);

      this.formTitle = `Resignation for ${this.resignation.fullName}`;

      this.selectFormPage(this.resignation);

    } else {
      // if creating a new resignation
      this.resignation = {

        resignationNames: [
          {
            firstName: null,
            middleName: null,
            surname: null,
            isPrimary: true,
          },
        ],
        birthYear: null,
        birthMonth: null,
        birthDay: null,

        minorFlag: null,
        parentTwoNotRequiredFlag: null,

        address: {
          country: null,
          stateProvince: null,
        },
      };
    }
  },
  methods: {
    // select the proper page when the form loads
    selectFormPage(resignation) {
      if (resignation.documentsDownloadedDate) {
        this.$refs.formWizard.changeTab(0, 1);
        this.$refs.formWizard.changeTab(0, 2);
        return;
      }

      if (resignation.resignationId > 0) {
        this.$refs.formWizard.changeTab(0, 1);
      }
    },

    // events from wizard
    async onResignationSaved(resignationId) {

      // this is an existing resignation
      if (this.resignationId) {
        await this.$refs.formWizard.nextTab();
      } else {
        this.$router.replace({ name: 'resignation-edit', params: { resignationId: resignationId } });
      }

      // reload the data from the back end
      this.resignationId = resignationId;
      await this.getResignation(resignationId);
    },

    onResignationDocsDownloadedNext() {
      this.$refs.formWizard.nextTab();
    },
    onResignationDocsUploadedNext() {
      this.$refs.formWizard.nextTab();
    },

    onDocumentDeleted(resignationFileId) {
      const idx = this.documents.findIndex(d => d.resignationFileId === resignationFileId);
      if (idx > -1) {
        this.documents.splice(idx, 1);
      }
    },

    async performUpload() {

      if (this.files.length === 0) {
        await this.$swal('No files selected', 'Please select one or more files and try again', 'warning');
        return;
      }

      try {
        this.uploadInProgress = true;

        await UserDocumentApi.uploadResignationDocuments(this.resignationId, this.files, (progress) => {
          const { total, loaded } = progress;
          this.uploadProgressPercent = (loaded / total * 100) - 1;
        });

        this.$swal({
          title: 'Success',
          text: 'Uploaded successfully!',
          icon: 'success',
        });

        this.files = [];

        await this.getFilesForResignation(this.resignationId);

      } catch (e) {
        handleError(e, 'Upload failed');
        console.error(e);
      } finally {
        this.uploadInProgress = false;
        this.uploadProgressPercent = 0;
      }
    },

    async userApproveResignation() {

      const userApproveResignationRequest = {
        resignationId: this.resignationId,
        agreeToTerms: this.agreeToTerms,
        agreeToDataStorage: this.agreeToDataStorage,
      };

      try {
        await ResignationApi.userApproveResignation(userApproveResignationRequest);
        await this.$swal({
          title: 'Submitted!',
          text: 'Your resignation has been submitted',
          icon: 'success',
        });
        this.$router.push({ name: 'resignation-pending', params: { resignationId: this.resignationId } });
      } catch (e) {
        handleError(e);
      }
    },
    /* formatFileNames(files) {
      if (files.length === 1) {
        return files[0].name;
      } else {
        return `${files.length} files selected`;
      }
    }, */

    fileUploadRules() {
      return [
        value => !value || value.size < 5000000 || 'Avatar size should be less than 2 MB!',
      ];
    },

    removeFile(file) {

      const idx = this.files.indexOf(file);
      if (idx > -1) {
        this.files.splice(idx, 1);
      }
    },

    loadSampleRecord() {
      this.resignation = {
        birthYear: '1980',
        birthMonth: 12,
        birthDay: '31',
        minorFlag: true,
        parentTwoNotRequiredFlag: false,
        address: {
          country: {
            countryId: 230,
            countryName: 'United States',
            countryCode2: 'US',
            countryCode3: 'US',
          },
          stateProvince: {
            stateProvinceId: 6,
            stateProvinceName: 'California'
          },
          provinceName: null,
          streetAddress1: '1234 Main Street',
          streetAddress2: 'Suite 1000',
          city: 'Burbank',
          postalCode: '91504',
        },
        birthDate: '1980-12-31T08:00:00.000Z',
        resignationNames: [
          {
            firstName: 'Sample',
            middleName: 'A',
            familyName: makeid(5),
            isPrimary: true
          }
        ],
        parentOneName: 'Parent One ' + makeid(8),
        parentTwoName: 'Parent Two ' + makeid(8),
        addressId: null,
        genderType: {
          number: 2,
          value: 'male',
          description: 'Male',
        },
        membershipActiveFlag: true,
        membershipYears: '15',
        membershipNumber: 'membership number',
      };
    },

    isFileSizeError(fileSize) {
      return fileSize > 2 * 1024 * 1024;
    },
  },
  computed: {

    sizeToUpload() {
      return this._.sumBy(this.files, f => f.size);
    },

    totalFileSize() {
      let totalSize = 0;

      if (this.files.length > 0) {
        totalSize += this._.sumBy(this.files, f => f.size);
      }

      if (this.documents.length > 0) {
        const sum = this._.sumBy(this.documents, f => f.fileSize);
        totalSize += sum;
      }

      return totalSize;
    },

    uploadTooLarge() {
      return this.totalFileSize > this.maxEmailAttachmentMb * 1024 * 1024;
    },

    hasAnyDocuments() {
      return this.documents.length > 0;
    },
    hasAgreedToTerms() {
      return this.agreeToTerms && this.agreeToDataStorage;
    },
  },
};

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
</script>

<style lang="scss"
       scoped>

.large-file-error {
  color: red;
}
</style>
