import { authAxios } from '@/api';
import { ROOT_URL, API_VER } from '@/app/constants';

const API_CONTROLLER = 'country';

export class CountryApi {
  static searchCountries(countryRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/country-search`;
    return authAxios.post(url, countryRequest);
  }

  static getCountryByCountryCode2(countryCode2) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/code2/${countryCode2}`;
    return authAxios.get(url);
  }
}
